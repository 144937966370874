export const validaEmail = ((email) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
        return true;
    } else {
        return false;
    }
})

export const abreviaString = ((texto, limite, limitador = '...') => {
    let last
    if(texto.length > limite){ 
        limite--
        last = texto.substr(limite - 1, 1)
        while(last!=' ' && limite > 0){
            limite--
            last = texto.substr(limite - 1, 1)
        }
        last = texto.substr(limite -2, 1)
        if(last == ',' || last == ';'  || last == ':'){
            texto = texto.substr(0,limite - 2) + limitador
        } else if(last == '.' || last == '?' || last == '!'){
            texto = texto.substr(0,limite - 1)
        } else {
            texto = texto.substr(0, limite - 1) + limitador
        }
    }
    return texto
})

export const saudacao = ( () => {
    let resp = ''
    let hr = new Date().getHours()
    if (hr >= 12 && hr < 18) {
        resp = "Boa tarde";
    } else if (hr >= 0 && hr < 12) {
        resp = "Bom dia";
    } else {
        resp = "Boa noite";
    }
    return  resp;
})

export const removeHtml = ((html) => {
    if(html){
        return html.replace(/<[^>]+>/g, '')
    }else{
        return ''
    }
})

export const converterParaMaiusculas = (input) => {
    if (typeof input !== 'string') {
        return input;
    }
    return input.split('').map(char => {
        if (char >= 'a' && char <= 'z') {
            return String.fromCharCode(char.charCodeAt(0) - 32);
        }
        return char;
    }).join('');
};

export const ocultaCaracteres = (input) => {
    if (typeof input !== 'string') {
        return input;
    }
    if (!input) {
        return '';
    } 
    if (input.length  <= 4) {
        return input;
    }
    const visibleChars = 4;
    return input.substring(0, visibleChars) + '*'.repeat(input.length - visibleChars);
}